/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 public/models/chair.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { BufferGeometry, Material, Vector3 } from 'three'
import * as THREE from 'three'
import { useCustomization } from '../context/Customisation'

interface ModelProps {
  scale?: [number, number, number]
  position?: [number, number, number]
}

interface ModelProps { }

interface NodesProps {
  material?: Material,
  geometry?: BufferGeometry,
  position?: number[] | Vector3
}

interface GLTFType {
  materials: { [name: string]: Material },
  nodes: { [name: string]: NodesProps }
}


const ChairModel = (props: ModelProps) => {
  const { nodes, materials } = useGLTF('/models/chair.gltf') as GLTFType
  //On ajoute Legs à notre useCustomization
  const { material, legs } = useCustomization()

  ///////////////////////////////////////////////////
  //*******************TEXTURE CUIR*******************
  ////////////////////////////////////////////////////

  const leatherTextureProps = useTexture({
    map: './textures/leather/Leather_008_Base Color.jpg',
    normalMap: './textures/leather/Leather_008_Normal.jpg',
    roughnessMap: './textures/leather/Leather_008_Roughness.jpg',
    aoMap: './textures/leather/Leather_008_Ambient Occlusion.jpg',
  })

  // AMELIORATION DE LA QUALITE DU CUIR
  leatherTextureProps.map.repeat.set(3, 3);
  leatherTextureProps.normalMap.repeat.set(3, 3);
  leatherTextureProps.roughnessMap.repeat.set(3, 3);
  leatherTextureProps.aoMap.repeat.set(3, 3);

  // AMELIORATION DE LA QUALITE DU CUIR ++
  leatherTextureProps.map.wrapS =
    leatherTextureProps.map.wrapT =

    leatherTextureProps.normalMap.wrapS =
    leatherTextureProps.normalMap.wrapT =

    leatherTextureProps.roughnessMap.wrapS =
    leatherTextureProps.roughnessMap.wrapT =

    leatherTextureProps.aoMap.wrapS =
    leatherTextureProps.aoMap.wrapT =

    THREE.RepeatWrapping

  ////////////////////////////////////////////////////
  //******************TEXTURE TISSU******************
  ///////////////////////////////////////////////////

  const fabricTextureProps = useTexture({
    map: './textures/fabric/Fabric_Knitted_006_basecolor.jpg',
    normalMap: './textures/fabric/Fabric_Knitted_006_normal.jpg',
    roughnessMap: './textures/fabric/Fabric_Knitted_006_roughness.jpg',
    aoMap: './textures/fabric/Fabric_Knitted_006_ambientOcclusion.jpg',
  })

  // AMELIORATION DE LA QUALITE DU CUIR
  fabricTextureProps.map.repeat.set(3, 3);
  fabricTextureProps.normalMap.repeat.set(3, 3);
  fabricTextureProps.roughnessMap.repeat.set(3, 3);
  fabricTextureProps.aoMap.repeat.set(3, 3);

  // AMELIORATION DE LA QUALITE DU CUIR ++
  fabricTextureProps.map.wrapS =
    fabricTextureProps.map.wrapT =

    fabricTextureProps.normalMap.wrapS =
    fabricTextureProps.normalMap.wrapT =

    fabricTextureProps.roughnessMap.wrapS =
    fabricTextureProps.roughnessMap.wrapT =

    fabricTextureProps.aoMap.wrapS =
    fabricTextureProps.aoMap.wrapT =

    THREE.RepeatWrapping

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Chair.geometry}>
        <meshStandardMaterial {...
          (material === "leather" ? leatherTextureProps : fabricTextureProps)} />
      </mesh>

      <mesh geometry={nodes.Cushion.geometry} position={[0, 0.064, 0.045]}>
        <meshStandardMaterial {...fabricTextureProps} />
      </mesh>
      <mesh geometry={nodes.Legs1.geometry} material={materials.Legs}
        //ON AJOUTE ICI CE QUI VA DECLENCHER LE CHANGEMENT D'OBJET
        visible={legs === 1} />
      <mesh geometry={nodes.Legs2.geometry} material={materials.Legs}
        visible={legs === 2} />
    </group>
  )
}

useGLTF.preload('/models/chair.gltf')

export default ChairModel